import logo from './logo.svg';
import './App.css';

window.onload = function() {
  var newUrl = "https://about.conten.tech/SUPERFAN";
  window.location.href = newUrl;
};

function App() {
  return (
<></>
  );
}

export default App;
